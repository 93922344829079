import { Component } from '@angular/core';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
})
export class ListadoComponent  {

  heroes: string[] = ['Siperman', 'Ironman', 'Hulk', 'Capitán America', 'Pantera Negra'];


  borrarHeroe() {
    console.log('borrando...')
  }

}
