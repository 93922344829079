<h1>{{nombre}}</h1>

<dl>

    <td>Nombre: </td>
    <dd>{{nombre}}</dd>

    <td>Edad: </td>
    <dd>{{edad}}</dd>

    <td>Función: </td>
    <dd>{{obtenerNombre()}}</dd>

    <td>Capitalizado: </td>
    <dd>{{nombreCapitalizado}}</dd>

</dl>

<button (click)="cambiarNombre()">
    Cambiar Héroe
</button>

<button (click)="cambiarEdad()">
    Cambiar edad
</button>