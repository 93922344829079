<p>listado de Héroes</p>

<ul>

    <li *ngFor="let heroe of heroes">
        {{heroe}}
    </li>

</ul>

<button (click)="borrarHeroe()">
    Borrar
</button>
