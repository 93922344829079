import { Component } from '@angular/core';

@Component({
    selector: 'app-contador', //Se puede especificar cualquier nombre
    template: `

        <h1>{{titulo}}</h1>
        <h3>La base es: <strong> {{base}}  </strong></h3>

        <button (click)="acumular(base)"> + 5</button>

        <samp> {{numero}} </samp> 
        <button (click)="acumular(-base)"> - 5</button>

    `
})

export class ContadorComponent {

    titulo = 'Contador App';
    numero: number = 5;
    base: number = 5;
  
    acumular(valor:number) {
      this.numero += valor;
    }

}